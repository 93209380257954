<template>
    dff
</template>
<script>
import { ref, onMounted, reactive, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
export default {
	components: {  },
    setup() {
		
		
		 
		return {
		}
	},
	mounted() {
		
	}
}
</script>